import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { Link } from 'gatsby'
import * as s_form from '@assets/styles/form.module.scss'
import * as s from './car-calculator-popup-cta.module.scss'
import * as s_btns from '@assets/styles/btns.module.scss'

const CarCalculatorPopupCTA = ({ showPopup, setShowPopup, messageValue }) => {
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors, isValid }
	} = useForm({ mode: 'onChange' })
	const [reqStatus, setReqStatus] = useState({
		isSuccess: false,
		isLoading: false,
		isError: false,
		errorMessage: ''
	})

	useEffect(() => {
		setValue('message', messageValue)
	}, [messageValue])

	const submitHandler = async (data) => {
		if (!reqStatus.isSuccess) {
			try {
				setReqStatus({
					isSuccess: false,
					isLoading: true,
					isError: false,
					errorMessage: ''
				})

				let formData = new FormData()
				formData.append('name-610', data.name)
				formData.append('company-610', data.company)
				formData.append('nip-610', data.nip)
				formData.append('email-133', data.email)
				formData.append('tel-610', data.tel)
				formData.append('message-871', data.message)
				formData.append('_wpcf7_unit_tag', 'single-vehicle-form')

				const res = await axios.post(
					`https://click-leasing.pl/panel/wp-json/contact-form-7/v1/contact-forms/3695/feedback`,
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}
				)

				setTimeout(() => {
					setShowPopup(false)
				}, 4000)

				setReqStatus({
					isSuccess: true,
					isLoading: false,
					isError: false,
					errorMessage: ''
				})
			} catch (error) {
				setReqStatus({
					isSuccess: false,
					isLoading: false,
					isError: true,
					errorMessage: 'Wystąpił błąd.'
				})
			}
		}
	}

	return (
		<>
			{showPopup == true && (
				<>
					<div className={s.shadow} onClick={() => setShowPopup(false)} />
					<div className={s.wrapper_popup}>
						<div className={s.close} onClick={() => setShowPopup(false)}></div>

						<form
							onSubmit={handleSubmit(submitHandler)}
							className={`${s_form.apply} ${s.form}`}
							id={`conv_single_vehicle_form`}
						>
							<div className={s.left_wrapper}>
								<h3>Zapytaj o pojazd</h3>

								{/* name */}
								<div className={s_form.field_wrapper}>
									<span className={s_form.error_text}>
										{errors.name?.type === 'required' && 'Wpisz imię i nazwisko.'}
									</span>
									<input
										id='form_contact_name'
										className={errors.name ? s_form.error : ''}
										placeholder='&nbsp;'
										type='text'
										{...register('name', {
											required: true
										})}
									/>
									<label htmlFor='form_contact_name'>Imię i nazwisko*</label>
								</div>

								{/* company */}
								<div className={s_form.field_wrapper}>
									<span className={s_form.error_text}></span>
									<input
										id='form_contact_company'
										className={errors.company ? s_form.error : ''}
										placeholder='&nbsp;'
										type='text'
										{...register('company')}
									/>
									<label htmlFor='form_contact_company'>Nazwa firmy</label>
								</div>

								{/* nip */}
								<div className={s_form.field_wrapper}>
									<span className={s_form.error_text}>
										{errors.nip?.type === 'pattern' && 'Niepoprawny NIP'}
									</span>
									<input
										id='form_contact_nip'
										className={errors.nip ? s_form.error : ''}
										placeholder='&nbsp;'
										type='text'
										onKeyPress={(event) => {
											if (!/[0-9- ]/.test(event.key)) {
												event.preventDefault()
											}
										}}
										{...register('nip', {
											pattern: /^[0-9- ]*$/
										})}
									/>
									<label htmlFor='form_contact_nip'>NIP</label>
								</div>

								{/* tel */}
								<div className={s_form.field_wrapper}>
									<span className={s_form.error_text}>
										{errors.tel?.type === 'required' && 'Wpisz numer telefonu.'}
										{errors.tel?.type === 'pattern' && 'Niepoprawny telefon'}
									</span>
									<input
										id='form_contact_tel'
										className={errors.tel ? s_form.error : ''}
										placeholder='&nbsp;'
										type='tel'
										onKeyPress={(event) => {
											if (!/[0-9 +-]/.test(event.key)) {
												event.preventDefault()
											}
										}}
										{...register('tel', {
											required: true,
											pattern: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
										})}
									/>
									<label htmlFor='form_contact_tel'>Numer telefonu*</label>
								</div>

								{/* email */}
								<div className={s_form.field_wrapper}>
									<span className={s_form.error_text}>
										{errors.email?.type === 'required' && 'Wpisz adres e-mail.'}
										{errors.email?.type === 'pattern' && 'Adres email wygląda na nieprawidłowy.'}
									</span>
									<input
										id='form_contact_email'
										className={errors.email ? s_form.error : ''}
										placeholder='&nbsp;'
										type='email'
										{...register('email', {
											required: true,
											pattern:
												/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
										})}
									/>
									<label htmlFor='form_contact_email'>E-mail*</label>
								</div>
							</div>

							<div className={s.right_wrapper}>
								<h3>Treść wiadomości</h3>

								{/* message */}
								<div className={s_form.field_wrapper}>
									<span className={s_form.error_text}>
										{errors.message?.type === 'required' && 'Wpisz wiadomość.'}
									</span>
									<div className={s_form.textarea_wrapper}>
										<textarea
											id='form_contact_message'
											className={errors.message ? s_form.error : ''}
											placeholder='&nbsp;'
											{...register('message', {
												required: true
											})}
										/>
										<label htmlFor='form_contact_message'>Treść wiadomości*</label>
									</div>
								</div>

								{/* rules */}
								<div className={s_form.field_wrapper}>
									<span className={s_form.error_text}>
										{errors.rules && 'Zaznacz wymagane zgody.'}
									</span>
									<div className={s_form.checkbox_wrapper}>
										<input
											className={errors.rules ? s_form.error : ''}
											type='checkbox'
											id={'form_contact_rules'}
											{...register('rules', {
												required: true
											})}
										/>
										<label className={s_form.checkbox_label} htmlFor={'form_contact_rules'}>
											Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z ustawą o
											ochronie danych osobowych w związku z realizacją mojego zapytania, oraz o
											kontakt drogą telefoniczną lub elektroniczną.{' '}
											<Link to='/polityka-prywatnosci' target='_blank'>
												Zobacz&nbsp;Politykę&nbsp;prywatności
											</Link>{' '}
											*
										</label>
									</div>
								</div>

								<div className={s_form.request_response}>
									<span className={s_form.error}>
										{reqStatus.isError && reqStatus.errorMessage}
									</span>
									<span className={s_form.success}>{reqStatus.isSuccess && 'Wysłano!'}</span>
								</div>

								{/* //loading spinner or effect on btn */}
								<button
									type='submit'
									className={`${
										!isValid || reqStatus.isSuccess || reqStatus.isLoading ? s_form.inactive : ''
									} ${s_btns.btn_full}`}
								>
									Wyślij
								</button>
							</div>
						</form>
					</div>
				</>
			)}
		</>
	)
}

export default CarCalculatorPopupCTA
